<template>
  <div class="itemHolder">
    <div class="header">
      <p class="title">Beds:</p>
      <div class="editIcon" @click="showThisAcc">
        <p class="text">
          {{ "(" + beds.length + (beds.length > 1 ? " beds)" : " bed)") }}
        </p>
      </div>
    </div>

    <div class="childItemContainer">
      <div class="item" v-for="(bed, index) in beds" :key="bed">
        <div class="deleteInList" @click="deleteBed(index)">+</div>
        <div class="editIcon" @click="showThisBed(index)">
          <img
            src="https://cdn-icons-png.flaticon.com/512/6244/6244035.png"
            alt=""
          />
        </div>

        <img
          v-if="bed.bedSize === 'SingleBed'"
          src="https://cdn-icons-png.flaticon.com/512/3837/3837744.png"
          alt=""
          @click="showThisBed(index)"
        />

        <img
          v-if="bed.bedSize === 'DoubleBed'"
          src="https://cdn-icons-png.flaticon.com/512/3837/3837739.png"
          alt=""
          @click="showThisBed(index)"
        />

        <div @click="showThisBed(index)">
          {{ bed.bedSize === "SingleBed" ? "Single Bed" : "Double Bed" }}
        </div>

        <div
          v-if="bed.bedSize === 'DoubleBed'"
          :style="{ 'font-size': 'small' }"
          @click="showThisBed(index)"
        >
          {{ bed.isShareable === false ? "Not Shareable" : "Shareable" }}
        </div>
      </div>

      <div class="item">
        <img
          @click="addBed()"
          src="https://cdn-icons-png.flaticon.com/512/875/875515.png"
          alt=""
        />
        <select v-model="bedSize">
          <option value="SingleBed">SingleBed</option>
          <option value="DoubleBed">DoubleBed</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["showThisBed", "addBed"],
  props: ["bedsProp", "bedIndexProp"],

  data() {
    return {
      beds: this.$props.bedsProp,
      bedSize: "SingleBed",
    };
  },

  watch: {
    bedProp(newValue) {
      this.beds = newValue;
    },
  },

  methods: {
    showThisBed(index) {
      this.$emit("showThisBed", index);
    },

    deleteBed(index) {
      this.beds.splice(index, 1);
    },

    addBed() {
      this.$emit("addBed", this.bedSize);
    },
  },
};
</script>

<style scoped>
p {
  color: black;
}

.editIcon {
  position: absolute;
  top: -1px;
  left: -10px;
  padding: 3px;
  cursor: pointer;
}

.editIcon img {
  height: 1.5em;
  width: 1.5em;
  align-self: center;
  padding: 0 1vw 0 1vw;
}

.delete {
  position: absolute;
  font-size: 25px;
  top: -6px;
  right: 2px;
  color: black;
  padding: 3px;
  cursor: pointer;
  transform: rotate(45deg);
}

.deleteInList {
  position: absolute;
  font-size: 20px;
  top: -10px;
  right: -1px;
  color: black;
  padding: 3px;
  cursor: pointer;
  transform: rotate(45deg);
}

.itemHolder p {
  margin: 0.2rem;
  min-height: 1rem;
}

.childItemContainer {
  display: grid;
  gap: 0.5rem;
  margin: 0.1rem 1rem 0.4rem 1rem;
  grid-template-columns: repeat(5, 1fr);
}

.header {
  display: grid;
  margin: 0.2em;
  border-bottom-style: groove;
  grid-template-columns: 1fr auto 1fr;
}

.header .editIcon {
  position: unset;
  top: unset;
  padding: 3px;
  cursor: pointer;
  grid-column-start: 2;
  left: unset;
  align-self: center;
  place-content: center;
}

.header .editIcon:hover {
  cursor: unset;
}

.header .title {
  left: 1rem;
  grid-column-start: 1;
  justify-self: self-start;
  margin-left: 1rem;
  align-self: center;
}

.item {
  border: outset;
  display: flex;
  flex-direction: column;
  height: 4.5rem;
  justify-content: center;
  position: relative;
  place-content: space-evenly;
}

.item select {
  width: 80%;
  align-self: center;
  text-align-last: center;
}

img {
  height: 2em;
  width: 2em;
  align-self: center;
  padding: 0 1vw 0 1vw;
}

img:hover {
  cursor: pointer;
  opacity: 0.5;
}

@media screen and (max-width: 426px) {
  .childItemContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .editIcon {
    left: 0;
  }

  .editIcon img {
    height: 1em;
    width: 1em;
  }
}
</style>
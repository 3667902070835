<template>
  <div class="itemContainer">
    <div class="header">
      <p class="title">Room:</p>
      <div class="editIcon" @click="showThisRoom">
        <img
          @click="showThisRoom"
          src="https://cdn-icons-png.flaticon.com/512/6244/6244035.png"
          alt=""
        />
        <p class="text" @click="showThisRoom">
          {{
            stringSizeCheck(room.roomTitle, 20) +
            " (" +
            room.beds.length +
            (room.beds.length > 1 ? " beds)" : " bed)")
          }}
        </p>
      </div>
      <div class="delete" @click="deleteRoom">
        <img
          src="https://cdn-icons-png.flaticon.com/512/458/458594.png"
          alt=""
        />
      </div>
    </div>
    <div class="childItemContainer">
      <div class="item" v-for="(bed, index) in room.beds" :key="bed">
        <div class="deleteInList" @click="deleteBed(index)">+</div>
        <div class="editIcon" @click="showThisBed(index)">
          <img
            src="https://cdn-icons-png.flaticon.com/512/6244/6244035.png"
            alt=""
          />
        </div>

        <img
          v-if="bed.bedSize === 'SingleBed'"
          src="https://cdn-icons-png.flaticon.com/512/3837/3837744.png"
          alt=""
          @click="showThisBed(index)"
        />

        <img
          v-if="bed.bedSize === 'DoubleBed'"
          src="https://cdn-icons-png.flaticon.com/512/3837/3837739.png"
          alt=""
          @click="showThisBed(index)"
        />

        <div @click="showThisBed(index)">
          {{ bed.bedSize === "SingleBed" ? "Single Bed" : "Double Bed" }}
        </div>

        <div
          @click="showThisBed(index)"
          v-if="bed.bedSize === 'DoubleBed'"
          :style="{ 'font-size': 'small' }"
        >
          {{ bed.isShareable === false ? "Not Shareable" : "Shareable" }}
        </div>
      </div>
      <div class="item">
        <img
          @click="addBed()"
          src="https://cdn-icons-png.flaticon.com/512/875/875515.png"
          alt=""
        />
        <select v-model="bedSize">
          <option value="SingleBed">SingleBed</option>
          <option value="DoubleBed">DoubleBed</option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["showThisRoom", "deleteRoom", "addBed", "showThisBed", "deleteBed"],
  props: ["roomProp"],

  data() {
    return {
      room: this.$props.roomProp,
      bedSize: "SingleBed",
    };
  },

  watch: {
    roomProp(newValue) {
      this.room = newValue;
    },
  },

  methods: {
    stringSizeCheck(string, size) {
      if (string.length > size) {
        return string.slice(0, size - 3) + "...";
      } else {
        return string;
      }
    },

    showThisRoom() {
      this.$emit("showThisRoom");
    },

    deleteRoom() {
      this.$emit("deleteRoom");
    },

    deleteBed(index) {
      this.$emit("deleteBed", index);
    },

    showThisBed(index) {
      this.$emit("showThisBed", index);
    },

    addBed() {
      this.$emit("addBed", this.bedSize);
    },
  },
};
</script>

<style scoped>
p {
  color: black;
}

.editIcon {
  position: absolute;
  top: -1px;
  left: -10px;
  padding: 3px;
  cursor: pointer;
}

.editIcon img {
  height: 1.5em;
  width: 1.5em;
  align-self: center;
  padding: 0 1vw 0 1vw;
}

.delete {
  grid-column-start: 3;
  justify-self: self-end;
  align-self: center;
}

.delete img {
  height: 1.5em;
  width: 1.5em;
}

.deleteInList {
  position: absolute;
  font-size: 20px;
  top: -10px;
  right: -1px;
  color: black;
  padding: 3px;
  cursor: pointer;
  transform: rotate(45deg);
}

.itemContainer p {
  margin: 0.2rem;
  min-height: 1rem;
  position: relative;
}

.itemContainer {
  display: grid;
}

.childItemContainer {
  display: grid;
  gap: 0.5rem;
  margin: 0.1rem 1rem 0.4rem 1rem;
  grid-template-columns: repeat(5, 1fr);
}

.header {
  display: grid;
  justify-content: center;
  margin: 0.2em;
  border-bottom-style: groove;
  grid-template-columns: 1fr auto 1fr;
}

.header .title {
  align-self: center;
  left: 1rem;
  grid-column-start: 1;
  place-self: self-start;
  align-self: center;
}

.title p {
  margin-left: 1rem;
}

.header .editIcon {
  position: unset;
  top: unset;
  padding: 3px;
  cursor: pointer;
  grid-column-start: 2;
  left: unset;
  align-self: center;
  display: grid;
  grid-template-columns: 22% auto;
}

.header .editIcon p {
  grid-column-start: 2;
}

.header .editIcon img {
  height: 1.5em;
  width: 1.5em;
  align-self: center;
  padding: 0 1vw 0 1vw;
  grid-column-start: 1;
}

.item {
  border: outset;
  display: flex;
  flex-direction: column;
  height: 4.5rem;
  justify-content: center;
  position: relative;
  place-content: center;
}

.item select {
  width: 80%;
  align-self: center;
  text-align-last: center;
}

img {
  height: 2em;
  width: 2em;
  align-self: center;
}

img:hover {
  cursor: pointer;
  opacity: 0.5;
}

.header img {
  margin-right: 1rem;
}

@media screen and (max-width: 426px) {
  .header {
    grid-template-columns: 1fr 10%;
  }

  .header .editIcon {
    grid-column: 1;
  }

  .delete {
    grid-column: 2;
    place-self: center;
  }

  .delete img {
    margin: 0;
  }

  .header .title {
    display: none;
  }

  .childItemContainer {
    grid-template-columns: repeat(2, 1fr);
  }

  .editIcon {
    left: 0;
  }

  .editIcon img {
    height: 1em;
    width: 1em;
  }
}
</style>
<template>
  <h1>
    <span class="headingHighlight">3. Confirmation</span>
  </h1>
  <div class="infoContainer">
    <div class="basicInfo">
      <div class="editIcon">
        <img
          @click="editThisItem('basicInfoContainer')"
          src="https://cdn-icons-png.flaticon.com/512/6244/6244035.png"
          alt=""
        />
      </div>
      <div class="sectionTitle">
        <h1>Basic Info:</h1>
      </div>
      <div class="country">
        <p class="title">Country:</p>
        <p>{{ event.country ? event.country : "Empty" }}</p>
      </div>

      <div class="city">
        <p class="title">City:</p>
        <p>{{ event.city ? event.city : "Empty" }}</p>
      </div>

      <div class="destinationDes">
        <p class="title">Destination description:</p>
        <p>
          {{
            event.destinationDescription
              ? event.destinationDescription
              : "Empty"
          }}
        </p>
      </div>

      <div class="startDateContainer">
        <p class="title">Start date:</p>
        <p>{{ event.startDate ? event.startDate : "Not Selected" }}</p>
      </div>

      <div class="endDateContainer">
        <p class="title">End date:</p>
        <p>{{ event.endDate ? event.endDate : "Not Selected" }}</p>
      </div>

      <div class="deadLineContainer">
        <p class="title">Application deadline:</p>
        <p>{{ event.deadLine ? event.deadLine : "Not Selected" }}</p>
      </div>

      <div class="bedCountContainer">
        <p class="title">Number of Beds:</p>
        <p>
          {{ countBedsForEvent() }}
        </p>
      </div>

      <div class="eventDescriptionContainer">
        <p class="title">Event description:</p>
        <p>{{ event.eventDescription ? event.eventDescription : "Empty" }}</p>
      </div>
    </div>

    <div class="customFields">
      <div class="editIcon">
        <img
          @click="editThisItem('basicInfoContainer')"
          src="https://cdn-icons-png.flaticon.com/512/6244/6244035.png"
          alt=""
        />
      </div>
      <div class="sectionTitle">
        <h1>Custom Fields:</h1>
      </div>
      <div
        class="customFieldContainer"
        v-for="(customfield, index) in event.customFields"
        :key="customfield"
      >
        <p>{{ index + 1 + ". " + customfield.customFieldDescription }}</p>
      </div>
    </div>

    <div class="surveys">
      <div class="editIcon">
        <img
          @click="editThisItem('basicInfoContainer')"
          src="https://cdn-icons-png.flaticon.com/512/6244/6244035.png"
          alt=""
        />
      </div>
      <div class="sectionTitle">
        <h1>Survey:</h1>
      </div>
      <div
        class="surveyContainer"
        v-for="(survey, index) in event.surveys"
        :key="survey"
      >
        <p class="title">
          {{
            "Question" +
            (index + 1) +
            ": " +
            (survey.type == "select" ? "(select)" : "(open anwser)")
          }}
        </p>
        <p>{{ survey.questionTitle }}</p>
        <p class="title" v-if="survey.type === 'select'">Options:</p>
        <p v-if="survey.type === 'select'">{{ survey.options }}</p>
      </div>
    </div>

    <div class="links">
      <div class="editIcon">
        <img
          @click="editThisItem('basicInfoContainer')"
          src="https://cdn-icons-png.flaticon.com/512/6244/6244035.png"
          alt=""
        />
      </div>
      <div class="sectionTitle">
        <h1>Links:</h1>
      </div>
      <div class="linkContainer" v-for="link in event.links" :key="link">
        <p class="title">
          {{ link.linkTitle ? link.linkTitle : "Empty Title" }} :
        </p>
        <a
          :href="
            link.linkDescription ? link.linkDescription : 'javascript:void(0)'
          "
          >{{ link.linkDescription ? link.linkDescription : "Empty Link" }}</a
        >
      </div>
    </div>

    <div class="accommodations">
      <div class="editIcon">
        <img
          class="editIconAccommodations"
          @click="editThisItem('showApartmentInfo')"
          src="https://cdn-icons-png.flaticon.com/512/6244/6244035.png"
          alt=""
        />
      </div>
      <div class="sectionTitle">
        <h1>Accommodations:</h1>
      </div>
      <div
        class="accContainer"
        v-for="(acc, accIndex) in event.accommodations"
        :key="acc"
      >
        <div class="editIcon">
          <img
            class="editIconAccommodation"
            @click="editThisItem('addAccommodation', accIndex)"
            src="https://cdn-icons-png.flaticon.com/512/6244/6244035.png"
            alt=""
          />
        </div>
        <div class="accTitle">
          <p class="title">Title:</p>
          <p>{{ acc.accommodationTitle }}</p>
        </div>

        <div class="accDes">
          <p class="title">Description:</p>
          <p>{{ acc.accommodationDescription }}</p>
        </div>

        <div class="accOverView">
          <p class="title">Overview:</p>
          <div class="roomsOfAccommodation">
            <p class="title">Number of rooms:</p>
            <p>{{ event.accommodations[accIndex].rooms.length }}</p>
          </div>

          <div class="bedsOfAccommodation">
            <p class="title">Number of beds:</p>
            <div class="singleBed">
              <p>SingleBeds:</p>
              <p>{{ countBedsForAcc(acc, "SingleBed") }}</p>
            </div>

            <div class="doubleBed">
              <p>DoubleBeds:</p>
              <p>{{ countBedsForAcc(acc, "DoubleBed") }}</p>
            </div>
          </div>
        </div>

        <div
          class="accRooms"
          v-if="event.accommodations[accIndex].rooms.length != 0"
        >
          <div>
            <p class="title">Rooms of accommodation:</p>
          </div>

          <div class="roomContianer">
            <div
              class="room"
              v-for="(room, roomIndex) in event.accommodations[accIndex].rooms"
              :key="room"
            >
              <div class="editIcon">
                <img
                  class="editIconRoom"
                  @click="editThisItem('addRoom', accIndex, roomIndex)"
                  src="https://cdn-icons-png.flaticon.com/512/6244/6244035.png"
                  alt=""
                />
              </div>
              <div class="roomTitle">
                <p class="title">{{ "Room" + (roomIndex + 1) + ": " }}</p>
                <p>{{ room.roomTitle }}</p>
              </div>

              <div class="roomDes">
                <p class="title">Description:</p>
                <p>{{ room.roomDescription }}</p>
              </div>

              <div class="bedsOfRoom">
                <p class="title">Number of beds:</p>
                <div class="singleBed">
                  <p>SingleBeds:</p>
                  <p>{{ countBedsForRoom(room, "SingleBed") }}</p>
                </div>

                <div class="doubleBed">
                  <p>DoubleBeds:</p>
                  <p>{{ countBedsForRoom(room, "DoubleBed") }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: ["event"],
  emits: ["editThisItem"],

  data() {
    return {};
  },

  methods: {
    countBedsForEvent() {
      let bedCount = 0;
      for (
        let accIndex = 0;
        accIndex < this.$props.event.accommodations.length;
        accIndex++
      ) {
        let accommodation = this.$props.event.accommodations[accIndex];
        if (accommodation.rooms.length != 0) {
          for (
            let roomIndex = 0;
            roomIndex < accommodation.rooms.length;
            roomIndex++
          ) {
            let room = accommodation.rooms[roomIndex];
            bedCount += room.beds.length;
          }
        }
      }
      return bedCount;
    },

    countBedsForAcc(acc, bedSize) {
      let singleBedCount = 0;
      let doubleBedCount = 0;
      let accommodation = acc;
      if (accommodation.rooms.length != 0) {
        for (
          let roomIndex = 0;
          roomIndex < accommodation.rooms.length;
          roomIndex++
        ) {
          let room = accommodation.rooms[roomIndex];
          if (room.beds.length != 0) {
            room.beds.forEach((bed) => {
              bed.bedSize == "SingleBed"
                ? (singleBedCount += 1)
                : (doubleBedCount += 1);
            });
          }
        }
      }
      return bedSize == "SingleBed" ? singleBedCount : doubleBedCount;
    },

    countBedsForRoom(room, bedSize) {
      let singleBedCount = 0;
      let doubleBedCount = 0;
      if (room.beds.length != 0) {
        room.beds.forEach((bed) => {
          bed.bedSize == "SingleBed"
            ? (singleBedCount += 1)
            : (doubleBedCount += 1);
        });
      }
      return bedSize == "SingleBed" ? singleBedCount : doubleBedCount;
    },

    editThisItem(command, accIndex, roomIndex) {
      let obj;
      if ((accIndex || accIndex == 0) && (roomIndex || roomIndex == 0)) {
        obj = {
          command: command,
          accIndex: accIndex,
          roomIndex: roomIndex,
        };
      } else if (accIndex || accIndex == 0) {
        obj = {
          command: command,
          accIndex: accIndex,
        };
      } else {
        obj = {
          command: command,
        };
      }
      this.$emit("editThisItem", obj);
    },
  },
};
</script>

<style scoped>
* {
  color: black;
}

p {
  justify-self: start;
  text-align: start;
}

.title {
  font-weight: 600;
}

.headingHighlight {
  margin-bottom: 20px;
  color: white;
}

.basicInfo,
.customFields,
.surveys,
.links,
.accommodations {
  border: double;
  margin: 30px 0 30px 0;
  gap: 15px;
  border-color: #8b78b0;
  border-width: thick;
  border-radius: 20px;
  padding: 1em;
  position: relative;
}

.infoContainer {
  display: grid;
  grid-template-columns: 60% 1fr;
  grid-column-gap: 1rem;
}

.basicInfo {
  display: grid;
  grid-column: 1 / span 2;
  grid-template-columns: repeat(2, 1fr);
  position: relative;
}

.sectionTitle {
  grid-column: 1 / span 2;
  margin: 0 2em;
}

.sectionTitle h1 {
  background-color: #c3c3e5;
  border-radius: 10px;
  padding: 10px;
}

.basicInfo .country,
.city,
.startDateContainer,
.endDateContainer {
  grid-column-start: 1;
  display: flex;
  margin-left: 2em;
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 10px;
}

.basicInfo .city,
.endDateContainer {
  grid-column-start: 2;
}

.basicInfo .deadLineContainer,
.bedCountContainer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-start: 1 / span 2;
  margin-left: 2em;
  padding: 10px;
}

.basicInfo .destinationDes,
.eventDescriptionContainer {
  grid-column: 1 / span 2;
  place-self: center;
  display: grid;
  grid-template-rows: auto 1fr;
  background-color: #8b78b0;
  border-radius: 10px;
  padding: 10px;
  margin: 0 2em;
  width: -webkit-fill-available;
}

.eventDescriptionContainer {
  background-color: #a8e0e1;
  margin-bottom: 1rem;
}

.destinationDes .title,
.eventDescriptionContainer .title {
  place-self: self-start;
  margin-bottom: 0.3em;
}

.customFieldContainer {
  text-align: start;
  margin: 2em;
}

.surveyContainer {
  display: grid;
  display: flex;
  flex-direction: column;
  margin: 2em;
  text-align: start;
}

.surveyContainer p {
  margin: 0;
}

.links,
.accommodations {
  position: relative;
  grid-column: 1 / span 2;
  display: flex;
  flex-direction: column;
}

.linkContainer {
  margin: 10px 2em;
  text-align: start;
}

.accContainer {
  text-align: start;
  display: grid;
  margin: 0 2em;
  background-color: lightblue;
  padding: 20px;
  border-radius: 10px;
  grid-gap: 1em;
  position: relative;
}

.bedsOfAccommodation {
  margin-left: 2rem;
}

.roomsOfAccommodation,
.singleBed,
.doubleBed {
  margin-left: 2rem;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1em;
}

.accTitle,
.roomTitle {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-column-gap: 1em;
}

.room {
  margin: 2rem;
  display: grid;
  border: solid;
  border-radius: 30px;
  padding: 10px;
  border-color: lightcyan;
  position: relative;
}

img {
  height: 1.5em;
  width: 1.5em;
}

.editIcon {
  height: 1.5em;
  width: 1.5em;
  position: absolute;
  padding: 0 1vw 0 1vw;
  top: 0.5em;
  right: 0.5em;
}

img:hover {
  cursor: pointer;
  opacity: 0.5;
}

@media screen and (max-width: 1024px) {
  .customFields,
  .surveys {
    grid-column: 1 / span 2;
  }
}

@media screen and (max-width: 426px) {
  .sectionTitle {
    margin: 0;
  }

  .basicInfo .country,
  .city,
  .startDateContainer,
  .endDateContainer {
    margin-left: 0;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: none;
  }

  .basicInfo .destinationDes,
  .eventDescriptionContainer {
    margin: 0;
    width: -webkit-fill-available;
  }

  .customFieldContainer,
  .surveyContainer,
  .linkContainer {
    margin: 1em 0;
  }

  .linkContainer {
    overflow: hidden;
  }

  .basicInfo .deadLineContainer,
  .bedCountContainer {
    display: grid;
    margin-left: 0;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: none;
    grid-column-start: 1 / span 2;
    padding: 10px;
  }

  .accContainer {
    margin: 0;
  }

  .room {
    margin: 2em 0;
  }
}
</style>
<template>
  <div class="itemContainer" v-for="(room, index) in rooms" :key="room">
    <div class="item" v-if="room.roomTitle || room.beds.length > 0">
      <RoomItem
        :roomProp="room"
        @showThisRoom="showThisRoom(index)"
        @deleteRoom="deleteRoom(index)"
        @addBed="addBed($event, index)"
        @showThisBed="showThisBed($event, index)"
        @deleteBed="deleteBed($event, index)"
      />
    </div>
  </div>
  <div class="addItem" @click="addRoom">
    <button class="button-styled-2">Add Room</button>
  </div>
</template>

<script>
import RoomItem from "./RoomItem.vue";
export default {
  emits: [
    "addRoom",
    "showThisRoom",
    "deleteRoom",
    "addBed",
    "showThisBed",
    "deleteBed",
  ],
  props: ["rooms"],

  components: {
    RoomItem,
  },

  data() {
    return {};
  },

  methods: {
    addRoom() {
      this.$emit("addRoom");
    },

    showThisRoom(index) {
      this.$emit("showThisRoom", index);
    },

    deleteRoom(index) {
      this.$emit("deleteRoom", index);
    },

    deleteBed(bedIndex, roomIndex) {
      this.$props.rooms[roomIndex].beds.splice(bedIndex, 1);
    },

    addBed(bedSize, roomIndex) {
      let obj = {
        bedSize: bedSize,
        roomIndex: roomIndex,
      };
      this.$emit("addBed", obj);
    },

    showThisBed(bedIndex, roomIndex) {
      let obj = {
        bedIndex: bedIndex,
        roomIndex: roomIndex,
      };

      this.$emit("showThisBed", obj);
    },
  },
};
</script>

<style scoped>
.item {
  border: outset;
  position: relative;
  margin: 1rem 0.5rem 1rem 0.5rem;
  color: black;
}

button:hover {
  background-color: #5c2abe;
}

.addItem {
  display: flex;
  place-content: center;
}

.button-styled-2 {
  height: 5vh;
  display: flex;
  align-items: center;
}
</style>
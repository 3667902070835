<template class="comp">
  <div class="itemContainer">
    <div class="header">
      <div class="title"><p>Accommodation:</p></div>
      <div class="editIcon" @click="showThisAcc">
        <img
          @click="showThisAcc"
          src="https://cdn-icons-png.flaticon.com/512/6244/6244035.png"
          alt=""
        />
        <p class="text" @click="showThisAcc">
          {{
            stringSizeCheck(acc.accommodationTitle, 20) +
            " (" +
            acc.rooms.length +
            (acc.rooms.length > 1 ? " rooms)" : " room)")
          }}
        </p>
      </div>
      <div class="delete" @click="deleteAcc">
        <img
          src="https://cdn-icons-png.flaticon.com/512/458/458594.png"
          alt=""
        />
      </div>
    </div>
    <div class="childItemContainer">
      <div class="item" v-for="(room, index) in acc.rooms" :key="room">
        <div class="deleteInList" @click="deleteRoom(index)">+</div>
        <div class="editIcon" @click="showThisRoom(index)">
          <img
            src="https://cdn-icons-png.flaticon.com/512/6244/6244035.png"
            alt=""
          />
        </div>

        <img
          src="https://cdn-icons-png.flaticon.com/512/3837/3837750.png"
          alt=""
          @click="showThisRoom(index)"
        />

        <div
          v-if="room.roomTitle != ''"
          class="itemContainer1"
          @click="showThisRoom(index)"
        >
          {{ stringSizeCheck(room.roomTitle, 7) }}
        </div>
        <div :style="{ 'font-Size': 'small' }" @click="showThisRoom(index)">
          {{
            "(" + room.beds.length + (room.beds.length > 1 ? " beds)" : " bed)")
          }}
        </div>
      </div>
      <div class="item" @click="addRoom()">
        <img
          src="https://cdn-icons-png.flaticon.com/512/875/875515.png"
          alt=""
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  emits: ["showThisRoom", "showThisAcc", "deleteAcc", "deleteRoom", "addRoom"],
  props: ["accommodationProp"],

  data() {
    return {
      acc: this.$props.accommodationProp,
    };
  },

  created() {},

  watch: {
    accommodationProp(newValue) {
      this.acc = newValue;
    },
  },

  methods: {
    stringSizeCheck(string, size) {
      if (string.length > size) {
        return string.slice(0, size - 3) + "...";
      } else {
        return string;
      }
    },

    deleteAcc() {
      this.$emit("deleteAcc");
    },

    deleteRoom(index) {
      this.$emit("deleteRoom", index);
    },

    showThisRoom(index) {
      this.$emit("showThisRoom", index);
    },

    showThisAcc() {
      this.$emit("showThisAcc");
    },

    addRoom() {
      this.$emit("addRoom");
    },
  },
};
</script>

<style scoped>
p {
  color: black;
}

.editIcon {
  position: absolute;
  top: -1px;
  left: -10px;
  padding: 3px;
  cursor: pointer;
}

.editIcon img {
  height: 1.5em;
  width: 1.5em;
  align-self: center;
  padding: 0 1vw 0 1vw;
}

.delete {
  grid-column-start: 3;
  justify-self: self-end;
  align-self: center;
}

.delete img {
  height: 1.5em;
  width: 1.5em;
}

.deleteInList {
  position: absolute;
  font-size: 20px;
  top: -10px;
  right: -1px;
  color: black;
  padding: 3px;
  cursor: pointer;
  transform: rotate(45deg);
}

.itemContainer p {
  margin: 0.2rem;
  min-height: 1rem;
  position: relative;
}

.itemContainer {
  display: grid;
}

.childItemContainer {
  display: grid;
  gap: 0.5rem;
  margin: 0.1rem 1rem 0.4rem 1rem;
  grid-template-columns: repeat(5, 1fr);
}

.header {
  display: grid;
  justify-content: center;
  margin: 0.2em;
  border-bottom-style: groove;
  grid-template-columns: 1fr auto 1fr;
}

.header .title {
  align-self: center;
  left: 1rem;
  grid-column-start: 1;
  place-self: self-start;
  align-self: center;
}

.title p {
  margin-left: 1rem;
}

.header .editIcon {
  position: unset;
  top: unset;
  padding: 3px;
  cursor: pointer;
  grid-column-start: 2;
  left: unset;
  align-self: center;
  place-content: center;
  display: grid;
  grid-template-columns: 15% 1fr;
}

.header .editIcon img {
  height: 1.5em;
  width: 1.5em;
  align-self: center;
  padding: 0 1vw 0 1vw;
}

.item {
  border: outset;
  display: flex;
  place-content: center;
  align-items: center;
  position: relative;
  flex-direction: column;
  height: 4.5rem;
}

img {
  height: 2em;
  width: 2em;
  align-self: center;
}

img:hover {
  cursor: pointer;
  opacity: 0.5;
}

.header img {
  margin-right: 1rem;
}

@media screen and (max-width: 426px) {
  .header {
    grid-template-columns: 1fr 10%;
  }

  .header .editIcon {
    grid-column: 1;
  }

  .delete {
    grid-column: 2;
    place-self: center;
  }

  .delete img {
    margin: 0;
  }

  .header .title {
    display: none;
  }

  .childItemContainer {
    grid-template-columns: repeat(2, 1fr);
  }
  .editIcon {
    left: 0;
  }

  .editIcon img {
    height: 1em;
    width: 1em;
  }
}
</style>
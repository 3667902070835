<template>
  <div class="itemContainer">
    <BedItem
      :bedsProp="beds"
      @showThisBed="showThisBed($event)"
      @addBed="addBed($event)"
    />
  </div>
</template>

<script>
import BedItem from "./BedItem.vue";
export default {
  emits: ["showThisBed", "addBed"],
  props: ["beds"],

  components: {
    BedItem,
  },

  data() {
    return {};
  },

  methods: {
    addBed(bedSize) {
      this.$emit("addBed", bedSize);
    },

    showThisBed(bedIndex) {
      this.$emit("showThisBed", bedIndex);
    },
  },
};
</script>

<style scoped>
* {
  color: black;
}
.item {
  border: outset;
  position: relative;
  margin: 1rem 0.5rem 1rem 0.5rem;
}

.itemContainer {
  border: outset;
  position: relative;
  margin: 1rem 0.5rem 1rem 0.5rem;
}
</style>